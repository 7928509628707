<template>
  <div class="w-100 h-100 d-flex justify-center align-center">
    <componentRegistry />
  </div>
</template>

<script>
import componentRegistry from '../../components/login/Registry.vue'

export default {
  name: 'Registry',
  components: {
    componentRegistry
  }
}
</script>
