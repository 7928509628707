<template>
  <div>
    <v-img @click="$router.push({name: 'Login'})" class="mx-auto mb-10" src="@/assets/img/logos/auth-logo.png"
           :height="$vuetify.breakpoint.smAndUp ? '109px': '116px'" contain style="cursor: pointer;"></v-img>
    <p class="auth-title mb-1">Registro</p>

    <v-form class="register-form" @submit.prevent="doRegister" ref="form">
      <div fluid class="mt-0 px-0">
        <v-row>
          <v-col cols="12" class="mb-0 mb-0 py-1">
            <base-text-field
              label="Usuario"
              v-model="username"
              :rules="[rules.required]"
              :error="errorMessages.username!=''"
              :error-messages="errorMessages.username"
            ></base-text-field>
          </v-col>
          <v-col cols="12" class="mt-0 pt-0 py-1">
            <base-text-field
              v-model="email"
              :rules="[rules.required, rules.email]"
              label="Correo electrónico"
              :error="errorMessages.email!=''"
              :error-messages="errorMessages.email"
            ></base-text-field>
          </v-col>
          <v-col cols="12" class="mt-0 pt-0 py-1">
            <base-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Contraseña"
              @click:append="show1 = !show1"
              :rules="[rules.required, rules.min(8), rules.containNumber, rules.containSymbol, rules.containUpperCase, rules.containLowerCase]"
              :error="errorMessages.password != ''"
              :error-messages="errorMessages.password"
              @focus="errorMessages.password = ''"
            ></base-text-field>
          </v-col>
          <v-col cols="12" class="mt-0 pt-0 py-1">
            <base-text-field
              v-model="confirmPassword"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              name="input-10-2"
              label="Repetir Contraseña"
              class="input-group--focused"
              @click:append="show3 = !show3"
              :rules="[rules.required, rules.min(8), rules.containNumber, rules.containSymbol, rules.containUpperCase, rules.containLowerCase, rules.equalTo('contraseña', password)]"
              :error="errorMessages.password != ''"
              :error-messages="errorMessages.password"
              @focus="errorMessages.password = ''"
            ></base-text-field>
          </v-col>
          <v-col class="botton-usamedic" cols="12">
            <base-button :loading="loading" @click="doRegister" class="full-width mb-3">Registrarme</base-button>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <p class="login-text mb-0 mx-2">¿Ya tienes una cuenta?</p>
            <p class="login-bold-text mb-0 mx-2" style="cursor: pointer;" @click="$router.push({name: 'Login'})">Iniciar
              sesión aquí</p>
          </v-col>
          <ConfirmModal ref="authConfirm"/>
        </v-row>
      </div>
    </v-form>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  required,
  min,
  equalTo,
  containNumber,
  containSymbol,
  containUpperCase,
  containLowerCase,
  email
} from '@/utils/rules'
import BaseButton from '@/components/auth/BaseButton'
import BaseTextField from '@/components/auth/BaseTextField'
import ConfirmModal from '@/components/auth/ConfirmModal'

export default {
  name: 'Registro',
  components: {
    BaseButton,
    BaseTextField,
    ConfirmModal
  },
  data () {
    return {
      loading: false,
      show1: false,
      show3: false,
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
      rules: { required, email, min, equalTo, containNumber, containSymbol, containUpperCase, containLowerCase },
      errorMessages: {
        username: '',
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions(['register']),
    async doRegister () {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          this.email = this.email.toLowerCase()
          const payload = {
            username: this.username,
            password1: this.password,
            password2: this.confirmPassword,
            email: this.email
          }
          await this.register(payload)
          await this.$refs.authConfirm.open({
            type: 'feedback',
            title: '¡Felicidades!',
            message: 'Gracias por registrarte. Te hemos enviado un mensaje al correo que ingresaste para verificar la cuenta.',
            acceptText: 'Confirmar'
          })
          await this.$router.push({ name: 'Login' })
        } catch (e) {
          this.errorMessages.username = e.response.data.username || ''
          this.errorMessages.email = e.response.data.email || ''
          this.errorMessages.password = e.response.data.non_field_errors || ''
          if (!this.errorMessages.username && !this.errorMessages.email && !this.errorMessages.password) {
            await this.$refs.authConfirm.open({
              type: 'error',
              title: '¡Ups!',
              message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos',
              acceptText: 'Confirmar'
            })
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.register-form {
  max-width: 448px;
  margin: auto;
}
</style>
